import React from "react"
import { Col, Card } from "react-bootstrap"
import { Link } from "gatsby"

const LinkCard = ({ to, title, bg='dark' }) => {
    return (
        <Col md={6}>
            <Card bg={bg} className="mt-3">
                <Link to={to}>
                    <Card.Body className="d-flex justify-content-center align-items-center">
                        <h2 className={`font-weight-bold text-center m-5 ${bg === 'pink' && 'text-dark-blue'}`}>{title}</h2>
                    </Card.Body>
                </Link>
            </Card>
        </Col>
    )
}

export default LinkCard
